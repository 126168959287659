/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, ContactForm, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"pomáhej s námi"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-9j14uq --full pb--60 pt--60" name={"y1yu2watfz"} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/3d99e5b275284d57afa36d67271509c8_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/3d99e5b275284d57afa36d67271509c8_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/3d99e5b275284d57afa36d67271509c8_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/3d99e5b275284d57afa36d67271509c8_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/3d99e5b275284d57afa36d67271509c8_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/3d99e5b275284d57afa36d67271509c8_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/3d99e5b275284d57afa36d67271509c8_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/3d99e5b275284d57afa36d67271509c8_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"oxh7rzvcctp"} style={{"backgroundColor":"rgba(224, 224, 224, 1)"}} parallax={false} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 --full" anim={"2"} animS={"4"} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"font-weight: bold; color: var(--black);\">On-line formulář pro všechny, kteří chtějí jakkoliv pomoci.</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--shape2">
              
              <ContactForm className="--shape2 --style2 fs--13" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-8uhabe css-42e4bw pb--60 pt--60" name={"pmw3tjsuisd"} layout={"l2"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/3f4d4fa8070f4fe99d6c46731528f235_bri=50_sat=0__s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/3f4d4fa8070f4fe99d6c46731528f235_bri=50_sat=0__s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/3f4d4fa8070f4fe99d6c46731528f235_bri=50_sat=0__s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/3f4d4fa8070f4fe99d6c46731528f235_bri=50_sat=0__s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/3f4d4fa8070f4fe99d6c46731528f235_bri=50_sat=0__s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/3f4d4fa8070f4fe99d6c46731528f235_bri=50_sat=0__s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/3f4d4fa8070f4fe99d6c46731528f235_bri=50_sat=0__s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/3f4d4fa8070f4fe99d6c46731528f235_bri=50_sat=0__s=3000x_.png);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim3 --center el--3 --full" anim={"3"} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/email-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold; color: var(--white);\">info@detivakci.org</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/phone-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold; color: var(--white);\">+420 735 239 759</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/adress-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold; color: var(--white);\">Pernerova 444, Pardubice, 530 02&nbsp;</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}