/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"akce"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center" style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"bd9iov63ube"} parallax={false}>
        </Column>


        <Column className="css-1p94duq css-42e4bw --full pb--60 pt--60" name={"m4jnjxhb8g"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/36f0702459fc4bce95cf5f1c2f938d7c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/36f0702459fc4bce95cf5f1c2f938d7c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/36f0702459fc4bce95cf5f1c2f938d7c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/36f0702459fc4bce95cf5f1c2f938d7c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/36f0702459fc4bce95cf5f1c2f938d7c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/36f0702459fc4bce95cf5f1c2f938d7c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/36f0702459fc4bce95cf5f1c2f938d7c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/36f0702459fc4bce95cf5f1c2f938d7c_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":18,"paddingBottom":3}} name={"4m8xzk484x"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"font-weight: bold;\">Podzimní pobyt v Janoušově<br>ve dnech 21.-24.11.2024</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w" style={{"paddingBottom":19,"paddingTop":13}} name={"ha8phe8wb2"}>
          
          <ColumnWrap className="column__flex --center el--2 --full" columns={"2"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/e7bd0d8ee4d74639a26bbe993fe1fb06_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/e7bd0d8ee4d74639a26bbe993fe1fb06_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/e7bd0d8ee4d74639a26bbe993fe1fb06_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/e7bd0d8ee4d74639a26bbe993fe1fb06_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/e7bd0d8ee4d74639a26bbe993fe1fb06_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/e7bd0d8ee4d74639a26bbe993fe1fb06_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Podzimní pobyt Janoušov<br><span style=\"font-weight: bold;\">Dětský domov Litovel</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/4faf9ab72f9f476894d53383ff4dc569_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/4faf9ab72f9f476894d53383ff4dc569_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/4faf9ab72f9f476894d53383ff4dc569_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/4faf9ab72f9f476894d53383ff4dc569_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/4faf9ab72f9f476894d53383ff4dc569_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/4faf9ab72f9f476894d53383ff4dc569_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Podzimní pobyt Janoušov<br><span style=\"font-weight: bold;\">Dětský domov Černá Voda</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/e590ab5c00c8429e94bda9d241356978_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/e590ab5c00c8429e94bda9d241356978_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/e590ab5c00c8429e94bda9d241356978_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/e590ab5c00c8429e94bda9d241356978_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/e590ab5c00c8429e94bda9d241356978_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/e590ab5c00c8429e94bda9d241356978_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Podzimní pobyt Janoušov\n<br><span style=\"font-weight: bold;\">Dětský diagn. ústav Hr. Králové</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/16e4d8b6b84d47799f9454594577231e_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/16e4d8b6b84d47799f9454594577231e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/16e4d8b6b84d47799f9454594577231e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/16e4d8b6b84d47799f9454594577231e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/16e4d8b6b84d47799f9454594577231e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/16e4d8b6b84d47799f9454594577231e_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Podzimní pobyt Janoušov\n<br><span style=\"font-weight: bold;\">Dětský domov Krásná Lípa</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"1r7cishxmc8"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"d8lhfmjq21"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"font-weight: bold; color: rgb(6, 35, 243);\">Program organizace v listopadu 2024</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">4.11.2024 - dětský domov Litovel</span> - návštěva dětí&nbsp;"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">9.11.2024 - dětský domov Velké Heraltice</span> - zahájení spolupráce"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">10.11.2024 - dětský domov Vizovice</span> - návštěva dětí&nbsp;"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">13.11.2024 - dětský domov Tisá</span> - zahájení spolupráce"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">14.11.2024 - výchovný ústav Pšov</span> - návštěva dětí&nbsp;"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">15.11.2024 - dětský domov se školou Místo</span> - návštěva dětí&nbsp;"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold;\">21.-24.11.2024 - dětský diagnostický ústav Hradec Králové, dětský domov Černá Voda, dětský domov Krásná Lípa, dětský domov Litovel</span> - podzimní pobyt - Janoušov&nbsp;"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}