/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Divider, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"podporujinas"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-17mjubb css-42e4bw --full pb--60 pt--60" name={"auo83z3v6o"} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/de47ef69d0f44a81a68fa0315364c930_bri=115__s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/de47ef69d0f44a81a68fa0315364c930_bri=115__s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/de47ef69d0f44a81a68fa0315364c930_bri=115__s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/de47ef69d0f44a81a68fa0315364c930_bri=115__s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/de47ef69d0f44a81a68fa0315364c930_bri=115__s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/de47ef69d0f44a81a68fa0315364c930_bri=115__s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/de47ef69d0f44a81a68fa0315364c930_bri=115__s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/de47ef69d0f44a81a68fa0315364c930_bri=115__s=3000x_.JPG);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-hmb2rv --center pb--80 pt--80" name={"uvod"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/d96af294178e4d8993130759b8d7b34b_bri=115__s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/d96af294178e4d8993130759b8d7b34b_bri=115__s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/d96af294178e4d8993130759b8d7b34b_bri=115__s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/d96af294178e4d8993130759b8d7b34b_bri=115__s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/d96af294178e4d8993130759b8d7b34b_bri=115__s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/d96af294178e4d8993130759b8d7b34b_bri=115__s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/d96af294178e4d8993130759b8d7b34b_bri=115__s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/d96af294178e4d8993130759b8d7b34b_bri=115__s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--62" content={"<br><br><br><br><br><br><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"dskej2548vf"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--26" content={"<span style=\"font-weight: bold;\">Vážená paní Ing. Libuše Janebová</span><br>"}>
              </Title>

              <Divider >
              </Divider>

              <Title className="title-box title-box--center fs--26" content={"<span style=\"font-weight: bold;\">599 dárců prostřednictvím Donio.cz&nbsp;</span>"}>
              </Title>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l12w" style={{"paddingTop":0,"paddingBottom":0}} name={"ow3zt3mxori"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/0c29d43aab95479f8ada36899bcdb514_e=461x0x1080x1080_s=660x_.png"} url={"https://hotelstart.cz/"} use={"url"} href={"https://hotelstart.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/0c29d43aab95479f8ada36899bcdb514_e=461x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/0c29d43aab95479f8ada36899bcdb514_e=461x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/0c29d43aab95479f8ada36899bcdb514_e=461x0x1080x1080_s=860x_.png 860w"} target={"_blank"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/923a0c32229d490f82c0e9b15086bcbc_s=660x_.png"} url={"http://jasamherna.cz/"} use={"url"} href={"http://jasamherna.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/923a0c32229d490f82c0e9b15086bcbc_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/923a0c32229d490f82c0e9b15086bcbc_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/923a0c32229d490f82c0e9b15086bcbc_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/44e220e4edf9421ba796fe748a312d34_e=447x0x1080x1080_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/44e220e4edf9421ba796fe748a312d34_e=447x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/44e220e4edf9421ba796fe748a312d34_e=447x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/44e220e4edf9421ba796fe748a312d34_e=447x0x1080x1080_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/75a98d48a7c140c0a66d69d34adbe8aa_e=418x0x1080x1080_bri=115_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/75a98d48a7c140c0a66d69d34adbe8aa_e=418x0x1080x1080_bri=115_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/75a98d48a7c140c0a66d69d34adbe8aa_e=418x0x1080x1080_bri=115_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/75a98d48a7c140c0a66d69d34adbe8aa_e=418x0x1080x1080_bri=115_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/1b25bfe8e8604ea58591cef87064f15b_e=402x0x1080x1080_bri=115_s=660x_.png"} url={"https://www.danos.cz/"} use={"url"} href={"https://www.danos.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/1b25bfe8e8604ea58591cef87064f15b_e=402x0x1080x1080_bri=115_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/1b25bfe8e8604ea58591cef87064f15b_e=402x0x1080x1080_bri=115_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/1b25bfe8e8604ea58591cef87064f15b_e=402x0x1080x1080_bri=115_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/27a26dac78de49a0af329191cd39d560_e=427x0x1080x1080_bri=115_s=660x_.png"} url={"https://www.jirout.com/"} use={"url"} href={"https://www.jirout.com/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/27a26dac78de49a0af329191cd39d560_e=427x0x1080x1080_bri=115_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/27a26dac78de49a0af329191cd39d560_e=427x0x1080x1080_bri=115_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/27a26dac78de49a0af329191cd39d560_e=427x0x1080x1080_bri=115_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/895d68bec1a348d983dc81d0ddbc9fad_e=422x0x1080x1080_s=660x_.png"} url={"https://horicke-trubicky.eu/"} use={"url"} href={"https://horicke-trubicky.eu/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/895d68bec1a348d983dc81d0ddbc9fad_e=422x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/895d68bec1a348d983dc81d0ddbc9fad_e=422x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/895d68bec1a348d983dc81d0ddbc9fad_e=422x0x1080x1080_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/ec1592119a3a42a0aa009049e15b8b26_e=390x0x1080x1080_s=660x_.png"} url={"https://www.xcomp.cz/"} use={"url"} href={"https://www.xcomp.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/ec1592119a3a42a0aa009049e15b8b26_e=390x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/ec1592119a3a42a0aa009049e15b8b26_e=390x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/ec1592119a3a42a0aa009049e15b8b26_e=390x0x1080x1080_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/c466045fc87a43a380a937b79ee90aa8_e=485x29x971x972_s=660x_.png"} url={"https://www.just-home.cz/"} use={"url"} href={"https://www.just-home.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/c466045fc87a43a380a937b79ee90aa8_e=485x29x971x972_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/c466045fc87a43a380a937b79ee90aa8_e=485x29x971x972_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/c466045fc87a43a380a937b79ee90aa8_e=485x29x971x972_s=860x_.png 860w"} target={"_blank"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/ee0f43e33ea247e080b7c343ae94e923_e=460x39x971x972_s=660x_.png"} url={"https://united-bakeries.cz/"} use={"url"} href={"https://united-bakeries.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/ee0f43e33ea247e080b7c343ae94e923_e=460x39x971x972_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/ee0f43e33ea247e080b7c343ae94e923_e=460x39x971x972_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/ee0f43e33ea247e080b7c343ae94e923_e=460x39x971x972_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/774547171a4541d7a7d711c7c164eeeb_e=481x69x971x972_s=660x_.png"} url={"https://www.evertile.cz/"} use={"url"} href={"https://www.evertile.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/774547171a4541d7a7d711c7c164eeeb_e=481x69x971x972_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/774547171a4541d7a7d711c7c164eeeb_e=481x69x971x972_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/774547171a4541d7a7d711c7c164eeeb_e=481x69x971x972_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/ec5ffccc583046ee931f4cc6002fbd1b_e=470x65x971x972_s=660x_.png"} url={"https://www.facebook.com/rotaractclubhk/"} use={"url"} href={"https://www.facebook.com/rotaractclubhk/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/ec5ffccc583046ee931f4cc6002fbd1b_e=470x65x971x972_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/ec5ffccc583046ee931f4cc6002fbd1b_e=470x65x971x972_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/ec5ffccc583046ee931f4cc6002fbd1b_e=470x65x971x972_s=860x_.png 860w"} target={"_blank"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/5ef0dca6dc494f96875093434750cef6_e=422x0x1080x1080_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/5ef0dca6dc494f96875093434750cef6_e=422x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/5ef0dca6dc494f96875093434750cef6_e=422x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/5ef0dca6dc494f96875093434750cef6_e=422x0x1080x1080_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/5cf13b411fe7486fafb9d4606fd53b48_e=402x0x1080x1080_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/5cf13b411fe7486fafb9d4606fd53b48_e=402x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/5cf13b411fe7486fafb9d4606fd53b48_e=402x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/5cf13b411fe7486fafb9d4606fd53b48_e=402x0x1080x1080_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/33219b2148a642018fd85b6bafcdafbe_e=445x0x1080x1080_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/33219b2148a642018fd85b6bafcdafbe_e=445x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/33219b2148a642018fd85b6bafcdafbe_e=445x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/33219b2148a642018fd85b6bafcdafbe_e=445x0x1080x1080_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/2e2f8523081e4494a7ec43138b892e07_e=416x0x1080x1080_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/2e2f8523081e4494a7ec43138b892e07_e=416x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/2e2f8523081e4494a7ec43138b892e07_e=416x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/2e2f8523081e4494a7ec43138b892e07_e=416x0x1080x1080_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/737e4cdae36741d49e678e94968cef46_e=424x0x1080x1080_s=660x_.png"} url={"https://www.pevnostpoznani.cz/"} use={"url"} href={"https://www.pevnostpoznani.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/737e4cdae36741d49e678e94968cef46_e=424x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/737e4cdae36741d49e678e94968cef46_e=424x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/737e4cdae36741d49e678e94968cef46_e=424x0x1080x1080_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/c1c57d95614f49ea818856173a47b8da_e=439x0x1080x1080_s=660x_.png"} url={"https://www.aqualand-moravia.cz/"} use={"url"} href={"https://www.aqualand-moravia.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/c1c57d95614f49ea818856173a47b8da_e=439x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/c1c57d95614f49ea818856173a47b8da_e=439x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/c1c57d95614f49ea818856173a47b8da_e=439x0x1080x1080_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/1e900a4086cc4fb68622fd09e6acf392_e=398x0x1080x1080_s=660x_.png"} url={"https://www.headstore.cz/"} use={"url"} href={"https://www.headstore.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/1e900a4086cc4fb68622fd09e6acf392_e=398x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/1e900a4086cc4fb68622fd09e6acf392_e=398x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/1e900a4086cc4fb68622fd09e6acf392_e=398x0x1080x1080_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/f207621b37d54e7688bff874cdab446b_e=424x0x1080x1080_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/f207621b37d54e7688bff874cdab446b_e=424x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/f207621b37d54e7688bff874cdab446b_e=424x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/f207621b37d54e7688bff874cdab446b_e=424x0x1080x1080_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/fe58a2f92d5d41d580575cc3a88b1ee9_e=418x0x1080x1080_s=660x_.png"} url={"https://bkpardubice.cz/"} use={"url"} href={"https://bkpardubice.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/fe58a2f92d5d41d580575cc3a88b1ee9_e=418x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/fe58a2f92d5d41d580575cc3a88b1ee9_e=418x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/fe58a2f92d5d41d580575cc3a88b1ee9_e=418x0x1080x1080_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/7423e8b8ec3746478a1eae8a423d203c_e=408x0x1080x1080_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/7423e8b8ec3746478a1eae8a423d203c_e=408x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/7423e8b8ec3746478a1eae8a423d203c_e=408x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/7423e8b8ec3746478a1eae8a423d203c_e=408x0x1080x1080_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/6c381451af95447aad429b1cc72362a6_e=416x0x1080x1080_s=660x_.png"} url={"https://www.hradyhop.cz/"} use={"url"} href={"https://www.hradyhop.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/6c381451af95447aad429b1cc72362a6_e=416x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/6c381451af95447aad429b1cc72362a6_e=416x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/6c381451af95447aad429b1cc72362a6_e=416x0x1080x1080_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/f188b5a95fe741bd80bedba41bdeeadf_e=435x0x1080x1080_s=660x_.png"} url={"https://www.cmgpv.cz/"} use={"url"} href={"https://www.cmgpv.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/f188b5a95fe741bd80bedba41bdeeadf_e=435x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/f188b5a95fe741bd80bedba41bdeeadf_e=435x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/f188b5a95fe741bd80bedba41bdeeadf_e=435x0x1080x1080_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujehttps://www.podnikavezenypce.cz/te kliknutím"} src={"https://cdn.swbpg.com/t/17142/70281417bb8e4ece8ebd978988bf16cc_e=443x0x1080x1080_s=660x_.png"} use={"page"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/70281417bb8e4ece8ebd978988bf16cc_e=443x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/70281417bb8e4ece8ebd978988bf16cc_e=443x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/70281417bb8e4ece8ebd978988bf16cc_e=443x0x1080x1080_s=860x_.png 860w"} target={"_blank"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/48209952446f463aa663d5735a4b0d93_e=422x0x1080x1080_s=660x_.png"} url={"https://www.pruhovanykocour.cz/"} use={"url"} href={"https://www.pruhovanykocour.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/48209952446f463aa663d5735a4b0d93_e=422x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/48209952446f463aa663d5735a4b0d93_e=422x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/48209952446f463aa663d5735a4b0d93_e=422x0x1080x1080_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/57a5c4edc0d84d4f9b23313e44aa05c2_e=429x0x1080x1080_s=660x_.png"} url={"https://www.avantgard.cz/"} use={"url"} href={"https://www.avantgard.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/57a5c4edc0d84d4f9b23313e44aa05c2_e=429x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/57a5c4edc0d84d4f9b23313e44aa05c2_e=429x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/57a5c4edc0d84d4f9b23313e44aa05c2_e=429x0x1080x1080_s=860x_.png 860w"} target={"_blank"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/f2a159fd2a1f4b02b0c2dfdc55655680_e=425x0x1080x1080_s=660x_.png"} url={"https://czechitband.com/"} use={"url"} href={"https://czechitband.com/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/f2a159fd2a1f4b02b0c2dfdc55655680_e=425x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/f2a159fd2a1f4b02b0c2dfdc55655680_e=425x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/f2a159fd2a1f4b02b0c2dfdc55655680_e=425x0x1080x1080_s=860x_.png 860w"} target={"_blank"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 --full" style={{"marginTop":0}} columns={"1"} fullscreen={true}>
          </ColumnWrap>

        </Column>


        <Column className="--l12w pb--60 pt--60" name={"ngb7k0beji8"}>
          
          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/4cdafa1f514b4c298399320116d6cd56_e=359x152x1199x827_s=660x_.png"} url={"http://www.hcchot.cz/"} use={"url"} href={"http://www.hcchot.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/4cdafa1f514b4c298399320116d6cd56_e=359x152x1199x827_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/4cdafa1f514b4c298399320116d6cd56_e=359x152x1199x827_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/4cdafa1f514b4c298399320116d6cd56_e=359x152x1199x827_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/be206f80daf943f493b7c504bc22eeae_e=388x215x1199x718_s=660x_.png"} url={"http://www.kavarnachotebor.cz/"} use={"url"} href={"http://www.kavarnachotebor.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/be206f80daf943f493b7c504bc22eeae_e=388x215x1199x718_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/be206f80daf943f493b7c504bc22eeae_e=388x215x1199x718_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/be206f80daf943f493b7c504bc22eeae_e=388x215x1199x718_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/565c2e7fc83946bba9f7a3b6a023bb55_e=284x121x1384x866_s=660x_.png"} url={"https://www.ubaruphotography.com/"} use={"url"} href={"https://www.ubaruphotography.com/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/565c2e7fc83946bba9f7a3b6a023bb55_e=284x121x1384x866_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/565c2e7fc83946bba9f7a3b6a023bb55_e=284x121x1384x866_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/565c2e7fc83946bba9f7a3b6a023bb55_e=284x121x1384x866_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/68254e0650e6443bad056d22abfa818e_s=860x_.png"} url={"https://www.fotbalovytymhvezd.cz/"} use={"url"} href={"https://www.fotbalovytymhvezd.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/68254e0650e6443bad056d22abfa818e_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/68254e0650e6443bad056d22abfa818e_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/68254e0650e6443bad056d22abfa818e_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/68254e0650e6443bad056d22abfa818e_s=1400x_.png 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/3f28e6d2b56143d2b0f140e0fcd8cf1e_s=860x_.png"} url={"vhttps://www.lonkofka.cz/"} use={"url"} href={"vhttps://www.lonkofka.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/3f28e6d2b56143d2b0f140e0fcd8cf1e_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/3f28e6d2b56143d2b0f140e0fcd8cf1e_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/3f28e6d2b56143d2b0f140e0fcd8cf1e_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/3f28e6d2b56143d2b0f140e0fcd8cf1e_s=1400x_.png 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/afa88b074b8d4d1fa4d54bf71fcf9324_s=860x_.png"} url={"https://www.obchudekslunicko.cz/"} use={"url"} href={"https://www.obchudekslunicko.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/afa88b074b8d4d1fa4d54bf71fcf9324_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/afa88b074b8d4d1fa4d54bf71fcf9324_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/afa88b074b8d4d1fa4d54bf71fcf9324_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/afa88b074b8d4d1fa4d54bf71fcf9324_s=1400x_.png 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/d1412c9c050a4765a299ac911cce5477_s=860x_.png"} url={"https://www.silcom-multimedia.cz/"} use={"url"} href={"https://www.silcom-multimedia.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/d1412c9c050a4765a299ac911cce5477_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/d1412c9c050a4765a299ac911cce5477_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/d1412c9c050a4765a299ac911cce5477_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/d1412c9c050a4765a299ac911cce5477_s=1400x_.png 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/b08585d37951474d80b971ff0ab5f440_s=860x_.png"} url={""} use={"url"} href={""} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/b08585d37951474d80b971ff0ab5f440_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/b08585d37951474d80b971ff0ab5f440_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/b08585d37951474d80b971ff0ab5f440_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/b08585d37951474d80b971ff0ab5f440_s=1400x_.png 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/9efaef36f9a24e29b41b96c24f43fa68_s=860x_.png"} url={"https://fleetcor.cz/"} use={"url"} href={"https://fleetcor.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/9efaef36f9a24e29b41b96c24f43fa68_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/9efaef36f9a24e29b41b96c24f43fa68_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/9efaef36f9a24e29b41b96c24f43fa68_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/9efaef36f9a24e29b41b96c24f43fa68_s=1400x_.png 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/d6055475c84f4d108671e191d45d84db_e=303x185x1226x724_s=660x_.png"} url={"https://www.foxconn.cz/"} use={"url"} href={"https://www.foxconn.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/d6055475c84f4d108671e191d45d84db_e=303x185x1226x724_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/d6055475c84f4d108671e191d45d84db_e=303x185x1226x724_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/d6055475c84f4d108671e191d45d84db_e=303x185x1226x724_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/fb6013ac867b41df90cc50106d700851_e=305x134x1260x772_s=660x_.png"} url={"https://www.akprp.cz/"} use={"url"} href={"https://www.akprp.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/fb6013ac867b41df90cc50106d700851_e=305x134x1260x772_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/fb6013ac867b41df90cc50106d700851_e=305x134x1260x772_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/fb6013ac867b41df90cc50106d700851_e=305x134x1260x772_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/6a20461fdad14d67bd2c8f3c96a3b2f6_e=445x152x1077x831_s=660x_.png"} url={"https://www.iqparkideon.cz/"} use={"url"} href={"https://www.iqparkideon.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/6a20461fdad14d67bd2c8f3c96a3b2f6_e=445x152x1077x831_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/6a20461fdad14d67bd2c8f3c96a3b2f6_e=445x152x1077x831_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/6a20461fdad14d67bd2c8f3c96a3b2f6_e=445x152x1077x831_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/c9d582ad923e4f79b8f919288f7df58e_s=860x_.png"} url={""} use={"url"} href={""} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/c9d582ad923e4f79b8f919288f7df58e_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/c9d582ad923e4f79b8f919288f7df58e_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/c9d582ad923e4f79b8f919288f7df58e_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/c9d582ad923e4f79b8f919288f7df58e_s=1400x_.png 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/bbffc5df6e1a422ebe37eca60452568a_s=860x_.png"} url={""} use={"url"} href={""} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/bbffc5df6e1a422ebe37eca60452568a_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/bbffc5df6e1a422ebe37eca60452568a_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/bbffc5df6e1a422ebe37eca60452568a_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/bbffc5df6e1a422ebe37eca60452568a_s=1400x_.png 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/f6dd344832f8471aafa77ea2e2491802_s=860x_.png"} url={""} use={"url"} href={""} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/f6dd344832f8471aafa77ea2e2491802_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/f6dd344832f8471aafa77ea2e2491802_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/f6dd344832f8471aafa77ea2e2491802_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/f6dd344832f8471aafa77ea2e2491802_s=1400x_.png 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 --full" style={{"marginTop":0}} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/b3e781e28fce461badb89909637783a3_s=860x_.png"} url={""} use={"url"} href={""} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/b3e781e28fce461badb89909637783a3_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/b3e781e28fce461badb89909637783a3_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/b3e781e28fce461badb89909637783a3_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/b3e781e28fce461badb89909637783a3_s=1400x_.png 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/520dacf63ce549f79f0b8a023e86fe51_s=860x_.png"} url={""} use={"url"} href={""} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/520dacf63ce549f79f0b8a023e86fe51_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/520dacf63ce549f79f0b8a023e86fe51_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/520dacf63ce549f79f0b8a023e86fe51_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/520dacf63ce549f79f0b8a023e86fe51_s=1400x_.png 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/11184e79c30442968c0f50ba7ce38204_s=860x_.png"} url={""} use={"url"} href={""} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/11184e79c30442968c0f50ba7ce38204_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/11184e79c30442968c0f50ba7ce38204_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/11184e79c30442968c0f50ba7ce38204_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/11184e79c30442968c0f50ba7ce38204_s=1400x_.png 1400w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}