/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"detskedomovynase"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-158t0hk css-42e4bw --center pb--80 pt--80" style={{"marginBottom":0}} name={"uvod"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/aedc6f1441b64310a7d3225449aaf6e2_con=110_ove=000000x25__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/aedc6f1441b64310a7d3225449aaf6e2_con=110_ove=000000x25__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/aedc6f1441b64310a7d3225449aaf6e2_con=110_ove=000000x25__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/aedc6f1441b64310a7d3225449aaf6e2_con=110_ove=000000x25__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/aedc6f1441b64310a7d3225449aaf6e2_con=110_ove=000000x25__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/aedc6f1441b64310a7d3225449aaf6e2_con=110_ove=000000x25__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/aedc6f1441b64310a7d3225449aaf6e2_con=110_ove=000000x25__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/aedc6f1441b64310a7d3225449aaf6e2_con=110_ove=000000x25__s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--20" style={{"maxWidth":293}} content={"<span style=\"color: rgb(255, 255, 255);\"><span style=\"font-weight: bold;\"><br><br><br><br><br><br><br><br><br><br><br><br><br><br></span></span><br><br><br><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l12w pb--60 pt--60" name={"m1v8wuii4ko"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/8911cfcd3afa47ca8702a167cb7a87ba_e=0x0x600x338_con=110_s=350x_ove=000000x25_.jpeg"} url={"http://www.ddjilova.cz/"} use={"url"} href={"http://www.ddjilova.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/8911cfcd3afa47ca8702a167cb7a87ba_e=0x0x600x338_con=110_s=350x_ove=000000x25_.jpeg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Brno - Jílová&nbsp;</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/7bf99b987f824c0ea0564e9113fb9a7f_e=0x0x600x338_con=110_s=350x_ove=000000x25_.jpeg"} url={"https://www.ddzatec.cz/"} use={"url"} href={"https://www.ddzatec.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/7bf99b987f824c0ea0564e9113fb9a7f_e=0x0x600x338_con=110_s=350x_ove=000000x25_.jpeg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Žatec</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/1ef4756a99124c86903be08a9739d97f_e=61x0x395x222_con=110_s=350x_ove=000000x25_.png"} url={"https://www.ddnv.cz/"} use={"url"} href={"https://www.ddnv.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/1ef4756a99124c86903be08a9739d97f_e=61x0x395x222_con=110_s=350x_ove=000000x25_.png 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-style: italic; font-weight: bold;\">dětský domov Nová Ves u Chotěboře&nbsp;</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/32c80c0f94f84fac945c787e6f05d454_e=0x30x560x315_con=110_s=350x_ove=000000x25_.jpg"} url={"https://www.ddcermna.cz/"} use={"url"} href={"https://www.ddcermna.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/32c80c0f94f84fac945c787e6f05d454_e=0x30x560x315_con=110_s=350x_ove=000000x25_.jpg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Dolní Čermná&nbsp;</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/97c39b959e41414b9c9c41cd09e78bc2_e=0x82x2431x1367_con=110_s=860x_ove=000000x25_.jpg"} url={"https://www.ddprostejov.cz/"} use={"url"} href={"https://www.ddprostejov.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/97c39b959e41414b9c9c41cd09e78bc2_e=0x82x2431x1367_con=110_s=350x_ove=000000x25_.jpg 350w, https://cdn.swbpg.com/t/17142/97c39b959e41414b9c9c41cd09e78bc2_e=0x82x2431x1367_con=110_s=660x_ove=000000x25_.jpg 660w, https://cdn.swbpg.com/t/17142/97c39b959e41414b9c9c41cd09e78bc2_e=0x82x2431x1367_con=110_s=860x_ove=000000x25_.jpg 860w, https://cdn.swbpg.com/t/17142/97c39b959e41414b9c9c41cd09e78bc2_e=0x82x2431x1367_con=110_s=1400x_ove=000000x25_.jpg 1400w, https://cdn.swbpg.com/t/17142/97c39b959e41414b9c9c41cd09e78bc2_e=0x82x2431x1367_con=110_s=2000x_ove=000000x25_.jpg 2000w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-style: italic; font-weight: bold;\">dětský domov Prostějov</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/5a29f0bd0f004335bada8a42d1f5b66a_e=0x114x800x450_con=110_s=660x_ove=000000x25_.jpg"} url={"https://www.ddplumlov.org/"} use={"url"} href={"https://www.ddplumlov.org/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/5a29f0bd0f004335bada8a42d1f5b66a_e=0x114x800x450_con=110_s=350x_ove=000000x25_.jpg 350w, https://cdn.swbpg.com/t/17142/5a29f0bd0f004335bada8a42d1f5b66a_e=0x114x800x450_con=110_s=660x_ove=000000x25_.jpg 660w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-style: italic; font-weight: bold;\">dětský domov Plumlov</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/3d00904db8964700af76ae283d1ad05d_e=0x27x2309x1299_con=110_s=860x_ove=000000x25_.jpg"} url={"http://www.zsaddlitovel.cz/"} use={"url"} href={"http://www.zsaddlitovel.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/3d00904db8964700af76ae283d1ad05d_e=0x27x2309x1299_con=110_s=350x_ove=000000x25_.jpg 350w, https://cdn.swbpg.com/t/17142/3d00904db8964700af76ae283d1ad05d_e=0x27x2309x1299_con=110_s=660x_ove=000000x25_.jpg 660w, https://cdn.swbpg.com/t/17142/3d00904db8964700af76ae283d1ad05d_e=0x27x2309x1299_con=110_s=860x_ove=000000x25_.jpg 860w, https://cdn.swbpg.com/t/17142/3d00904db8964700af76ae283d1ad05d_e=0x27x2309x1299_con=110_s=1400x_ove=000000x25_.jpg 1400w, https://cdn.swbpg.com/t/17142/3d00904db8964700af76ae283d1ad05d_e=0x27x2309x1299_con=110_s=2000x_ove=000000x25_.jpg 2000w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-style: italic; font-weight: bold;\">dětský domov Litovel</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/c58e1385e7004b568bf21d93a4c11b48_e=140x0x599x337_con=110_s=350x_ove=000000x25_.png"} url={"https://www.ddholice.cz/"} use={"url"} href={"https://www.ddholice.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/c58e1385e7004b568bf21d93a4c11b48_e=140x0x599x337_con=110_s=350x_ove=000000x25_.png 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Holice&nbsp;</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/fe2bd2d6400b46399caf4dea85f24ac4_e=122x0x780x439_con=110_s=660x_ove=000000x25_.jpg"} url={"http://www.ddsedlonov.cz/"} use={"url"} href={"http://www.ddsedlonov.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/fe2bd2d6400b46399caf4dea85f24ac4_e=122x0x780x439_con=110_s=350x_ove=000000x25_.jpg 350w, https://cdn.swbpg.com/t/17142/fe2bd2d6400b46399caf4dea85f24ac4_e=122x0x780x439_con=110_s=660x_ove=000000x25_.jpg 660w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Sedloňov</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/943d3e27c3ca4f0e881a2993d43052b6_e=9x0x791x445_con=110_s=660x_ove=000000x25_.jpg"} url={"http://www.ddmtrebova.cz/"} use={"url"} href={"http://www.ddmtrebova.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/943d3e27c3ca4f0e881a2993d43052b6_e=9x0x791x445_con=110_s=350x_ove=000000x25_.jpg 350w, https://cdn.swbpg.com/t/17142/943d3e27c3ca4f0e881a2993d43052b6_e=9x0x791x445_con=110_s=660x_ove=000000x25_.jpg 660w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-style: italic; font-weight: bold;\">dětský domov Mor. Třebová</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/11485109d2be4739b5203725382a26ee_e=5x256x2285x1285_con=110_s=860x_ove=000000x25_.jpg"} url={"http://www.ddu-hk.cz/"} use={"url"} href={"http://www.ddu-hk.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/11485109d2be4739b5203725382a26ee_e=5x256x2285x1285_con=110_s=350x_ove=000000x25_.jpg 350w, https://cdn.swbpg.com/t/17142/11485109d2be4739b5203725382a26ee_e=5x256x2285x1285_con=110_s=660x_ove=000000x25_.jpg 660w, https://cdn.swbpg.com/t/17142/11485109d2be4739b5203725382a26ee_e=5x256x2285x1285_con=110_s=860x_ove=000000x25_.jpg 860w, https://cdn.swbpg.com/t/17142/11485109d2be4739b5203725382a26ee_e=5x256x2285x1285_con=110_s=1400x_ove=000000x25_.jpg 1400w, https://cdn.swbpg.com/t/17142/11485109d2be4739b5203725382a26ee_e=5x256x2285x1285_con=110_s=2000x_ove=000000x25_.jpg 2000w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-style: italic; font-weight: bold;\">dětský diagnostický ústav Hr. Králové&nbsp;&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/22caca88dce0446697fe31232e305138_e=0x0x1024x576_con=110_s=660x_ove=000000x25_.jpg"} url={"https://www.pardubicezive.eu/deti-z-detskeho-domova-v-horni-cermne-se-prestehuji-do-letohradu-a-zichlinku/"} use={"url"} href={"https://www.pardubicezive.eu/deti-z-detskeho-domova-v-horni-cermne-se-prestehuji-do-letohradu-a-zichlinku/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/22caca88dce0446697fe31232e305138_e=0x0x1024x576_con=110_s=350x_ove=000000x25_.jpg 350w, https://cdn.swbpg.com/t/17142/22caca88dce0446697fe31232e305138_e=0x0x1024x576_con=110_s=660x_ove=000000x25_.jpg 660w, https://cdn.swbpg.com/t/17142/22caca88dce0446697fe31232e305138_e=0x0x1024x576_con=110_s=860x_ove=000000x25_.jpg 860w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Žichlínek&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/898db9721b2d4d959d9937fab04155c2_e=0x39x640x360_con=110_s=350x_ove=000000x25_.jpg"} url={"https://www.youtube.com/watch?v=YAkf8e44RCw"} use={"url"} href={"https://www.youtube.com/watch?v=YAkf8e44RCw"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/898db9721b2d4d959d9937fab04155c2_e=0x39x640x360_con=110_s=350x_ove=000000x25_.jpg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Letohrad&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/f0301875fdd04a75880098e3851247c0_e=0x0x600x338_con=110_s=350x_ove=000000x25_.jpeg"} url={"https://www.ddplumlov.org/"} use={"url"} href={"https://www.ddplumlov.org/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/f0301875fdd04a75880098e3851247c0_e=0x0x600x338_con=110_s=350x_ove=000000x25_.jpeg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-style: italic; font-weight: bold;\">dětský domov Lanškroun</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/dfc58487c4734c35aedd8b67a1d2271e_e=33x0x1121x630_con=110_s=660x_ove=000000x25_.jpeg"} url={"http://www.ddhrotovice.cz/"} use={"url"} href={"http://www.ddhrotovice.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/dfc58487c4734c35aedd8b67a1d2271e_e=33x0x1121x630_con=110_s=350x_ove=000000x25_.jpeg 350w, https://cdn.swbpg.com/t/17142/dfc58487c4734c35aedd8b67a1d2271e_e=33x0x1121x630_con=110_s=660x_ove=000000x25_.jpeg 660w, https://cdn.swbpg.com/t/17142/dfc58487c4734c35aedd8b67a1d2271e_e=33x0x1121x630_con=110_s=860x_ove=000000x25_.jpeg 860w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Hrotovice&nbsp;&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/fe98e6de58374bf3910a2b6451204ae1_e=0x26x600x338_con=110_s=350x_ove=000000x25_.jpg"} url={"https://ddstrekov.wordpress.com/"} use={"url"} href={"https://ddstrekov.wordpress.com/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/fe98e6de58374bf3910a2b6451204ae1_e=0x26x600x338_con=110_s=350x_ove=000000x25_.jpg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Střekov&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/6b4a458bb5f04324ac610533ab47b855_e=39x0x587x330_con=110_s=350x_ove=000000x25_.jpg"} url={"https://www.dd-mikulov.eu/"} use={"url"} href={"https://www.dd-mikulov.eu/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/6b4a458bb5f04324ac610533ab47b855_e=39x0x587x330_con=110_s=350x_ove=000000x25_.jpg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov  Mikulov&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/dfb5d0cfbef74b7d9442a1862b720ea0_e=0x0x800x450_con=110_s=660x_ove=000000x25_.jpg"} url={"https://detskydomov-skolnijidelna-nechanice.wbs.cz/"} use={"url"} href={"https://detskydomov-skolnijidelna-nechanice.wbs.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/dfb5d0cfbef74b7d9442a1862b720ea0_e=0x0x800x450_con=110_s=350x_ove=000000x25_.jpg 350w, https://cdn.swbpg.com/t/17142/dfb5d0cfbef74b7d9442a1862b720ea0_e=0x0x800x450_con=110_s=660x_ove=000000x25_.jpg 660w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Nechanice&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/5047c01168c14040ad7af634820b4018_e=0x0x600x338_con=110_s=350x_ove=000000x25_.jpeg"} url={"https://www.ddssjihlava.cz/"} use={"url"} href={"https://www.ddssjihlava.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/5047c01168c14040ad7af634820b4018_e=0x0x600x338_con=110_s=350x_ove=000000x25_.jpeg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov se školou Jihlava&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/7b978c861fc744f585db9b23ccea318c_e=0x201x1920x1079_con=110_s=860x_ove=000000x25_.jpeg"} url={"http://www.fodpardubice.cz/"} use={"url"} href={"http://www.fodpardubice.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/7b978c861fc744f585db9b23ccea318c_e=0x201x1920x1079_con=110_s=350x_ove=000000x25_.jpeg 350w, https://cdn.swbpg.com/t/17142/7b978c861fc744f585db9b23ccea318c_e=0x201x1920x1079_con=110_s=660x_ove=000000x25_.jpeg 660w, https://cdn.swbpg.com/t/17142/7b978c861fc744f585db9b23ccea318c_e=0x201x1920x1079_con=110_s=860x_ove=000000x25_.jpeg 860w, https://cdn.swbpg.com/t/17142/7b978c861fc744f585db9b23ccea318c_e=0x201x1920x1079_con=110_s=1400x_ove=000000x25_.jpeg 1400w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">Klokánek Pardubice&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/a074c9798ef14426b595c97b7c13b8b4_e=0x31x750x422_con=110_s=660x_ove=000000x25_.jpg"} url={"http://www.ddkl.cz/"} use={"url"} href={"http://www.ddkl.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/a074c9798ef14426b595c97b7c13b8b4_e=0x31x750x422_con=110_s=350x_ove=000000x25_.jpg 350w, https://cdn.swbpg.com/t/17142/a074c9798ef14426b595c97b7c13b8b4_e=0x31x750x422_con=110_s=660x_ove=000000x25_.jpg 660w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-style: italic; font-weight: bold;\">dětský domov Krásná Lípa&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/f902f4cc6d3544cdadc91d069ff519bd_e=0x67x640x360_con=110_s=350x_ove=000000x25_.jpg"} url={"https://www.ddtachov.cz/"} use={"url"} href={"https://www.ddtachov.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/f902f4cc6d3544cdadc91d069ff519bd_e=0x67x640x360_con=110_s=350x_ove=000000x25_.jpg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Tachov&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/142f2193897a4285b7db9b8cfc69e538_e=0x0x400x225_con=110_s=350x_ove=000000x25_.JPG"} url={"https://www.vychovnyustavbrandys.cz/"} use={"url"} href={"https://www.vychovnyustavbrandys.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/142f2193897a4285b7db9b8cfc69e538_e=0x0x400x225_con=110_s=350x_ove=000000x25_.JPG 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">výchovný ústav Vysoké Mýto</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/1aec7ed04d734aa39141cebc35ce0b09_e=85x3x681x383_bri=115_s=660x_.jpg"} url={"https://www.skolanacelne.cz/detsky-domov/"} use={"url"} href={"https://www.skolanacelne.cz/detsky-domov/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/1aec7ed04d734aa39141cebc35ce0b09_e=85x3x681x383_bri=115_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/1aec7ed04d734aa39141cebc35ce0b09_e=85x3x681x383_bri=115_s=660x_.jpg 660w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Mladá Boleslav</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/1ae3088f41a24412a09268e502d96b50_e=0x215x1536x865_s=860x_.jpg"} url={"https://www.ddvrbno.cz/"} use={"url"} href={"https://www.ddvrbno.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/1ae3088f41a24412a09268e502d96b50_e=0x215x1536x865_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/1ae3088f41a24412a09268e502d96b50_e=0x215x1536x865_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/1ae3088f41a24412a09268e502d96b50_e=0x215x1536x865_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/1ae3088f41a24412a09268e502d96b50_e=0x215x1536x865_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Vrbno pod Pradědem&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/f4fb5398688c4c0a9ccf040fb2461aae_e=23x37x480x270_s=350x_.jpg"} url={"https://www.ddssjihlava.cz/"} use={"url"} href={"https://www.ddssjihlava.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/f4fb5398688c4c0a9ccf040fb2461aae_e=23x37x480x270_s=350x_.jpg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Trnava&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/2ddba7197de04168ad54086f1e57c4b7_e=0x19x640x360_s=350x_.jpg"} url={"http://www.ddlipnik.cz/"} use={"url"} href={"http://www.ddlipnik.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/2ddba7197de04168ad54086f1e57c4b7_e=0x19x640x360_s=350x_.jpg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Lipník nad Bečvou</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/d9a3e044df0348b3a99a08e6798de495_e=180x102x819x461_s=660x_.jpg"} url={"https://www.ddzsvizovice.cz/"} use={"url"} href={"https://www.ddzsvizovice.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/d9a3e044df0348b3a99a08e6798de495_e=180x102x819x461_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/d9a3e044df0348b3a99a08e6798de495_e=180x102x819x461_s=660x_.jpg 660w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Vizovice&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/004d0fc8438b4fc39b83b6e49328a2f9_e=155x72x985x554_s=660x_.jpg"} url={"https://www.ddlip.cz/cz/"} use={"url"} href={"https://www.ddlip.cz/cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/004d0fc8438b4fc39b83b6e49328a2f9_e=155x72x985x554_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/004d0fc8438b4fc39b83b6e49328a2f9_e=155x72x985x554_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/004d0fc8438b4fc39b83b6e49328a2f9_e=155x72x985x554_s=860x_.jpg 860w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Lipová u Šluknova</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/cd0be8154c3f43ea8302581bcf93abae_e=65x9x947x533_bri=85_s=660x_.jpg"} url={"https://www.ddkrupka.cz/"} use={"url"} href={"https://www.ddkrupka.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/cd0be8154c3f43ea8302581bcf93abae_e=65x9x947x533_bri=85_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/cd0be8154c3f43ea8302581bcf93abae_e=65x9x947x533_bri=85_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/cd0be8154c3f43ea8302581bcf93abae_e=65x9x947x533_bri=85_s=860x_.jpg 860w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Krupka</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/70600c8c22374a42abc0b73788949be8_e=512x0x772x434_bri=85_s=660x_.jpg"} url={"http://ddslety.cz/VYCHOVA/index.html#"} use={"url"} href={"http://ddslety.cz/VYCHOVA/index.html#"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/70600c8c22374a42abc0b73788949be8_e=512x0x772x434_bri=85_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/70600c8c22374a42abc0b73788949be8_e=512x0x772x434_bri=85_s=660x_.jpg 660w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Dobříchovice</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/6b6650c8a0d64ed4ad13d0737e6b7a1c_e=89x0x688x387_s=660x_.jpg"} url={"https://www.ddshamr.cz/"} use={"url"} href={"https://www.ddshamr.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/6b6650c8a0d64ed4ad13d0737e6b7a1c_e=89x0x688x387_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/6b6650c8a0d64ed4ad13d0737e6b7a1c_e=89x0x688x387_s=660x_.jpg 660w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov se školou Hamr na Jezeře&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/801a3fb37aee4f7b9ecb3e994848519a_e=69x0x480x270_s=350x_.jpeg"} url={"http://www.fodpardubice.cz/klokanek"} use={"url"} href={"http://www.fodpardubice.cz/klokanek"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/801a3fb37aee4f7b9ecb3e994848519a_e=69x0x480x270_s=350x_.jpeg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">Klokánek Pardubice</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/d32c8afe52944cd4a0226e8f73edcf18_e=80x53x640x360_s=350x_.jpg"} url={"https://detskydomov-skolnijidelna-nechanice.wbs.cz/"} use={"url"} href={"https://detskydomov-skolnijidelna-nechanice.wbs.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/d32c8afe52944cd4a0226e8f73edcf18_e=80x53x640x360_s=350x_.jpg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Nechanice</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/fbcbab86612a48959ba4971fd83f0771_s=350x_.jpeg"} url={"http://ddslety.cz/VYCHOVA/index.html#"} use={"url"} href={"http://ddslety.cz/VYCHOVA/index.html#"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/fbcbab86612a48959ba4971fd83f0771_s=350x_.jpeg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Rovečné&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/bb1409fa03864441993c87e83e00b7ea_s=350x_.jpg"} url={"https://www.ddshamr.cz/"} use={"url"} href={"https://www.ddshamr.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/bb1409fa03864441993c87e83e00b7ea_s=350x_.jpg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov se školou Místo</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/579a2229650b46d9bca5839af89386cd_s=350x_.jpg"} url={"http://www.fodpardubice.cz/klokanek"} use={"url"} href={"http://www.fodpardubice.cz/klokanek"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/579a2229650b46d9bca5839af89386cd_s=350x_.jpg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Horní Slavkov</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/13b4528c0a3e41d19ec654e9587f2c3d_s=660x_.jpeg"} url={"http://ddslety.cz/VYCHOVA/index.html#"} use={"url"} href={"http://ddslety.cz/VYCHOVA/index.html#"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/13b4528c0a3e41d19ec654e9587f2c3d_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/17142/13b4528c0a3e41d19ec654e9587f2c3d_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/17142/13b4528c0a3e41d19ec654e9587f2c3d_s=860x_.jpeg 860w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Zašová&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/14570764ea224fb2b0b123cfeaf2c1d6_s=350x_.jpg"} url={"https://www.ddshamr.cz/"} use={"url"} href={"https://www.ddshamr.cz/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/14570764ea224fb2b0b123cfeaf2c1d6_s=350x_.jpg 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Tisá</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17142/9e51b9baf12c49f28335b51c6272ef9f_s=350x_.png"} url={"http://www.fodpardubice.cz/klokanek"} use={"url"} href={"http://www.fodpardubice.cz/klokanek"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/9e51b9baf12c49f28335b51c6272ef9f_s=350x_.png 350w"} lightbox={false}>
              </Image>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"font-weight: bold; font-style: italic;\">dětský domov Velké Heraltice</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 --full" columns={"4"} fullscreen={true}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}