/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Děti v akci "}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1hvldnb --full pb--60 pt--60" name={"3pdsdl4cedu"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/3f0fea1e3599402d824e391b6aa5cad4_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/3f0fea1e3599402d824e391b6aa5cad4_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/3f0fea1e3599402d824e391b6aa5cad4_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/3f0fea1e3599402d824e391b6aa5cad4_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/3f0fea1e3599402d824e391b6aa5cad4_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/3f0fea1e3599402d824e391b6aa5cad4_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/3f0fea1e3599402d824e391b6aa5cad4_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/3f0fea1e3599402d824e391b6aa5cad4_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --right el--1 flex--center" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":650}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}