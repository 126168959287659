/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, Title, Image, Button, Fullmap, FullmapWrap, YouTube, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Vánoce"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-ewy5zm --center pb--80 pt--80" name={"uvod"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/855638db1b824d03bf08cd990691159c_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/855638db1b824d03bf08cd990691159c_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/855638db1b824d03bf08cd990691159c_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/855638db1b824d03bf08cd990691159c_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/855638db1b824d03bf08cd990691159c_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/855638db1b824d03bf08cd990691159c_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/855638db1b824d03bf08cd990691159c_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/855638db1b824d03bf08cd990691159c_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center fs--24" content={"<span style=\"font-weight: bold; color: var(--black);\">Děkujeme, že jste se stali Ježíškem dětem z DD.</span>"}>
              </Text>

              <Title className="title-box title-box--left fs--62" content={"<span style=\"color: var(--color-dominant);\"><br><br><br><br></span><br><br><br><br><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":22,"paddingTop":42}} name={"gki68s0k2f"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/d679f29a1c1541ee8b1a0934313956c2_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/d679f29a1c1541ee8b1a0934313956c2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/d679f29a1c1541ee8b1a0934313956c2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/d679f29a1c1541ee8b1a0934313956c2_s=860x_.jpg 860w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/ed5200763ffe4f958087bae226c7fad4_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/ed5200763ffe4f958087bae226c7fad4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/ed5200763ffe4f958087bae226c7fad4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/ed5200763ffe4f958087bae226c7fad4_s=860x_.jpg 860w"} position={null} lightbox={false} use={"url"} href={"https://www.instagram.com/p/DCizZsDIPeb/?igsh=dW1qaHlweW4yeWdj&img_index=1"} url={"https://www.instagram.com/p/DCizZsDIPeb/?igsh=dW1qaHlweW4yeWdj&img_index=1"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/9ea1ae3059744e07ad725dfb599db3fa_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/9ea1ae3059744e07ad725dfb599db3fa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/9ea1ae3059744e07ad725dfb599db3fa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/9ea1ae3059744e07ad725dfb599db3fa_s=860x_.jpg 860w"} position={null} lightbox={false} use={"url"} href={"https://www.instagram.com/p/DCizT4mo2k4/?igsh=bWNrY3VnZml6NWl2&img_index=1"} url={"https://www.instagram.com/p/DCizT4mo2k4/?igsh=bWNrY3VnZml6NWl2&img_index=1"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/88bf3984d907464bb8d966d06cffe165_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/88bf3984d907464bb8d966d06cffe165_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/88bf3984d907464bb8d966d06cffe165_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/88bf3984d907464bb8d966d06cffe165_s=860x_.jpg 860w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/72fc46c24f1e42dfb1d29ee6a202e120_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/72fc46c24f1e42dfb1d29ee6a202e120_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/72fc46c24f1e42dfb1d29ee6a202e120_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/72fc46c24f1e42dfb1d29ee6a202e120_s=860x_.jpg 860w"} position={null} lightbox={false} use={"url"} href={"https://www.instagram.com/p/DCizZsDIPeb/?igsh=dW1qaHlweW4yeWdj&img_index=1"} url={"https://www.instagram.com/p/DCizZsDIPeb/?igsh=dW1qaHlweW4yeWdj&img_index=1"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/cc416967b33f44a7842e7a8fb0f131aa_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/cc416967b33f44a7842e7a8fb0f131aa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/cc416967b33f44a7842e7a8fb0f131aa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/cc416967b33f44a7842e7a8fb0f131aa_s=860x_.jpg 860w"} position={null} lightbox={false} use={"url"} href={"https://www.instagram.com/p/DCizT4mo2k4/?igsh=bWNrY3VnZml6NWl2&img_index=1"} url={"https://www.instagram.com/p/DCizT4mo2k4/?igsh=bWNrY3VnZml6NWl2&img_index=1"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w" style={{"paddingBottom":0,"paddingTop":15}} name={"0lcrm8v8j5gk"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/10b06b96dd064dde8dc676bd34c040b3_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/10b06b96dd064dde8dc676bd34c040b3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/10b06b96dd064dde8dc676bd34c040b3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/10b06b96dd064dde8dc676bd34c040b3_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/5cdcc4d4e6b14adb8cf4c48790ab68eb_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/5cdcc4d4e6b14adb8cf4c48790ab68eb_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/5cdcc4d4e6b14adb8cf4c48790ab68eb_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/5cdcc4d4e6b14adb8cf4c48790ab68eb_s=860x_.png 860w, https://cdn.swbpg.com/t/17142/5cdcc4d4e6b14adb8cf4c48790ab68eb_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"qs2y8dnzy6f"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"font-weight: bold;\">Vánoce v dětských domovech</span>"}>
              </Title>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\">Vánoce se blíží. Ne každý je ale může trávit doma s rodinou a s těmi, které má rád. Sám vím, jaké to je zůstat jako dítě na Vánoce v dětském domově osamocen a právě proto jsem se rozhodl po odchodu z dětského domova dělat každý rok Vánoce dětem v dětských domovech příjemnější, hezčí a s těmi, které mají rádi.</span>"}>
              </Text>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\">Děti z dětských domovů si nepřejí žádné drahé dary ani cennosti. Jsou to naprosto běžná přání jako u ostatních dětí. U mladších dětí se nejčastěji jedná o hračky nebo různé hry či sportovní potřeby a u starších dětí už spíše převažují praktické dárky, které mohou využít ve svém životě i po odchodu z dětského domova.</span>"}>
              </Text>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\">Pomůžeme dětem v dětských ústavních zařízení po celé České republice. Velmi často se jedná o dětí, které prošly a procházejí velmi složitým životním obdobím i přes svůj velmi nízký věk. Konkrétní příklady dětí, kterým plníme přání: Dívka (13 let), druhým rokem v dětském domově, zneužívaná a týraná vlastním otcem. Chlapec (10 let), třetím rokem v dětském domově, týraný a zanedbávaný vlastními rodiči. a řada dalších …<br><br>Zapojené dětské domovy:\n \nDD Nová Ves u Chotěboře, DDÚ Hradec Králové,\nDD Mladá Boleslav, DD Sedloňov, DDŠ Místo,\nDD Krásná Lípa, DD Vizovice, DD Zašová,\nDD Litovel, DD Velké Heraltice,\nDD Černá Voda, DD Tisá,  DD Rovečné, \n\n\n a řada dalších …</span><br>"}>
              </Text>

              <Button className="btn-box" content={"<span style=\"font-weight: bold;\">DONIO.CZ</span>"} url={"https://donio.cz/vanoce-detem-kterym-osud-nepral"} href={"https://donio.cz/vanoce-detem-kterym-osud-nepral"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"xohgb9jkxib"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="--l12w pb--60 pt--60" name={"srero9e9lbj"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/67f63ef493a945d0817159e22ce39a2c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/67f63ef493a945d0817159e22ce39a2c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/67f63ef493a945d0817159e22ce39a2c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/67f63ef493a945d0817159e22ce39a2c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/67f63ef493a945d0817159e22ce39a2c_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/95a0963b6a2d449294f5163a1a2b9808_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/95a0963b6a2d449294f5163a1a2b9808_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/95a0963b6a2d449294f5163a1a2b9808_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/95a0963b6a2d449294f5163a1a2b9808_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/95a0963b6a2d449294f5163a1a2b9808_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/535c1ae6a3d7440fb52a7fb7ce025515_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/535c1ae6a3d7440fb52a7fb7ce025515_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/535c1ae6a3d7440fb52a7fb7ce025515_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/535c1ae6a3d7440fb52a7fb7ce025515_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/d8357183a4cf44f1ba98851c2e4c391c_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/d8357183a4cf44f1ba98851c2e4c391c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/d8357183a4cf44f1ba98851c2e4c391c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/d8357183a4cf44f1ba98851c2e4c391c_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/3b2993d4a21e4a6ebdcbd75952f13939_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/3b2993d4a21e4a6ebdcbd75952f13939_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/3b2993d4a21e4a6ebdcbd75952f13939_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/3b2993d4a21e4a6ebdcbd75952f13939_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/3b2993d4a21e4a6ebdcbd75952f13939_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/3b2993d4a21e4a6ebdcbd75952f13939_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/3b72a45fbb644f21a519b0e6f0acc4ae_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/3b72a45fbb644f21a519b0e6f0acc4ae_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/3b72a45fbb644f21a519b0e6f0acc4ae_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/3b72a45fbb644f21a519b0e6f0acc4ae_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/3b72a45fbb644f21a519b0e6f0acc4ae_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/28051c991100444691531ea9965ff4d2_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/28051c991100444691531ea9965ff4d2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/28051c991100444691531ea9965ff4d2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/28051c991100444691531ea9965ff4d2_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/f41c64ec857440d0bbc260f126e09028_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17142/f41c64ec857440d0bbc260f126e09028_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/f41c64ec857440d0bbc260f126e09028_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/f41c64ec857440d0bbc260f126e09028_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/f41c64ec857440d0bbc260f126e09028_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--60" style={{"paddingBottom":33}} name={"70vterkmfzl"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"font-weight: bold; font-style: italic;\">Snídaně s Novou</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">Adámek a Katrin z dětského domova Černá Voda<br>Klára Vavrušková - patronka organizace Děti v akci&nbsp;<br></span><span style=\"color: var(--black);\">Ladislav Samek - ředitel organizace Děti v akci<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"xykP3yJdKas"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"ofrrevanig"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column layout={"l8"} style={{"paddingTop":0,"paddingBottom":0}} name={"ecbc53eu07"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/6e4774f10b994221b8b1225c58fb1740_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/17142/6e4774f10b994221b8b1225c58fb1740_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/6e4774f10b994221b8b1225c58fb1740_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/6e4774f10b994221b8b1225c58fb1740_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/261a773c1ad440318d5dc3b9845f8336_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/17142/261a773c1ad440318d5dc3b9845f8336_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/261a773c1ad440318d5dc3b9845f8336_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/261a773c1ad440318d5dc3b9845f8336_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/d1478f54277f4a30912293593770662c_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/17142/d1478f54277f4a30912293593770662c_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/d1478f54277f4a30912293593770662c_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/d1478f54277f4a30912293593770662c_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/620dedfce84f4d138b475263eccfb046_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/17142/620dedfce84f4d138b475263eccfb046_s=350x_.png 350w, https://cdn.swbpg.com/t/17142/620dedfce84f4d138b475263eccfb046_s=660x_.png 660w, https://cdn.swbpg.com/t/17142/620dedfce84f4d138b475263eccfb046_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}